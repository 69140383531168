<template>
  <div>
    <div :class="['detail-box']">
      <div class="d-top">
        <div class="img">
          <img :src="backgrundImage" />
        </div>
        <div class="text-box">
          <span class="title">{{ detailData.caseTitle }}</span>
          <i class="en-title">{{ detailData.caseSecondTitle }}</i>
        </div>
      </div>
      <div :class="['title-url']">
        <div class="title">
          <span @click="goHome()">{{$t('Home')}}</span>
          <span class="line"></span>
          <span @click="$router.push('/IndustrySolutions')">{{$t('Solutions')}}</span>
        </div>
      </div>
      <div class="d-center">
        <div class="sol-function two-item">
          <div class="sol-top">
            <div class="sol-title">
              <span>客户痛点</span>
            </div>
            <div class="text" v-html="detailData.customerQuestions"></div>
          </div>
          <div class="sol-top">
            <div class="sol-title">
              <span>客户需求</span>
            </div>
            <div class="text" v-html="detailData.customerDemand"></div>
          </div>
        </div>
        <div class="sol-function">
          <div class="sol-top">
            <div class="sol-title">
              <span>方案简介</span>
            </div>
            <div class="text" v-html="detailData.caseText"></div>
          </div>
        </div>
      </div>
      <div class="d-run-video">
        <div class="sol-title">
          <span>运行视频</span>
        </div>
        <div class="sol-function">
          <video :src="detailData.runVideo" controls></video>
        </div>
      </div>
      <!-- <div class="d-bottom">
        <div class="sol-title">
          <span>经典案例</span>
        </div>
        <div class="work-together">
          <ul>
            <li v-for="(item, index) in detailData.caseExt" :key="index">
              <img :src="item.picture" />
              <span>{{ item.title }}</span>
            </li>
          </ul>
        </div>

        <div class="sol-title">
          <span>相关产品</span>
        </div>
        <div class="about-product">
          <ul>
            <li v-for="(item, index) in detailData.productExt" :key="index">
              <img :src="item.productImg" @click="goToProFun(item)" />
              <span>{{ item.productName }}</span>
            </li>
          </ul>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'IndustrySolutionsDetail',
  data() {
    return {
      detailData: {
        functionExt: '',
        functionDescribeExt: '',
        caseImgs: '',
        caseName: '',
        caseText: '',
        caseTypeId: '',
        caseTypeName: '',
        functionExt: '',
        functionDescribeExt: '',
        functionExt: '',
        functionDescribeExt: '',
        caseImgs: '',
        customerQuestions: '',
        customerDemand: '',
        runVideo: ''
      },
      backgrundImage: ''
    }
  },

  mounted() {
    this.getCaseDetail()
  },
  watch: {
    '$route.params.id'(newVal, oldVal) {
      if (newVal) {
        this.getCaseDetail()
      }
    }
  },
  methods: {
    // 获取案例详情
    getCaseDetail() {
      let formData = {
        id: this.$route.params.id
      }
      this.$axios
        .post('/officialweb/data/getGwCaseInfo', formData)
        .then((res) => {
          // console.log("res==>",res);
          if (res.data) {
            this.detailData = res.data
            this.detailData.caseExt = JSON.parse(this.detailData.caseExt)
            this.detailData.productExt = JSON.parse(this.detailData.productExt)
            this.backgrundImage = res.data.caseTitleImg
            // console.log("this.detailData==>",this.detailData.productExt);
          }
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="less" scoped>
@mainColor: #e6031a;
@blueColor: #004ea2;
@mainSize: 1400px;
// 设置详情页面
.detail-box {
  width: 100%;
  height: 100%;
  .d-top {
    width: 100%;
    height: 500px;
    background-size: cover;
    padding-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    // overflow: hidden;
    .img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      z-index: 0;
      // overflow: hidden;
      img {
        width: 100%;
        // height: 100%;
        object-fit: cover;
      }
    }
    .text-box {
      width: @mainSize;
      z-index: 10;
      color: #fff;
      // padding: 40px 0 40px 15%;
      box-sizing: border-box;
      .title {
        display: block;
        font-weight: bold;
        font-size: 56px;
        letter-spacing: 2px;
      }
      .en-title {
        display: block;
        text-align: center;
        width: 800px;
        font-size: 44px;
        margin: 0 auto;
        font-weight: bold;
        color: #004ea2;
        text-transform: uppercase;
      }
    }
  }
  .d-center {
    width: 100%;
    margin-top: 100px;
    background-color: #f8f8f8;
  }
  .d-run-video {
    width: 100%;
    margin-top: 100px;
  }
  .d-bottom {
    margin: 100px 0;
    background-color: #f8f8f8;
  }
  .title-url {
    width: 100%;
    height: 60px;
    line-height: 60px;
    border-bottom: 1px solid #e6e6e6;
  }
  .title-url .title {
    width: 1400px;
    text-align: left;
    margin: 0 auto;
    cursor: pointer;
  }
  .title-url .title span:hover {
    color: #e6031a;
  }
  .title-url .title .line {
    display: inline-block;
    width: 15px;
    height: 2px;
    margin: 0 6px;
    margin-bottom: 4px;
    background-color: #ccc;
  }
}
// 设置详情页面的标题
.sol-title {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 40px;
  // font-family: "Microsoft Yahei","微软雅黑","DINPro-L","微软正黑体","Microsoft JhengHei","SimHei"黑体",Arial,Helvetica,sans-serif;
  > span {
    font-size: 30px;
    color: #333;
    margin: 20px 0;
    font-weight: bold;
    padding-bottom: 1%;
    margin-bottom: 3%;
    position: relative;
    margin-top: 0;
    background: linear-gradient(@mainColor, @mainColor) no-repeat;
    background-size: 30% 3.5px;
    background-position: 50% 100%;
  }
}
// 设置详情页面
.sol-function {
  width: 1200px;
  min-height: 300px;
  margin: 0 auto;
  padding: 30px 0;
  .text {
    font-size: 18px;
    line-height: 30px;
    text-align: justify;
    text-indent: 2em;
    visibility: visible;
    animation-name: slideInUp;
  }
  &.two-item {
    display: flex;
    .sol-top {
      flex: 1;
      background-color: #fff;
      padding: 10px;
      transition: all 0.5s;
      &:first-child {
        margin-right: 20px;
      }
      &:hover {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        transform: translateY(2px);
      }
    }
  }
  video {
    height: 500px;
  }
}
</style>
